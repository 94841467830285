<template>
    <div>
        <StoreHeader />

        <div class="body-content">
            <slot />
        </div>

        <StoreFooter />
    </div>
</template>

<script lang="ts" setup>
    import metaTags from "@/constants/seo-constants";

    const route = useRoute();

    onMounted(() => {
        setMetaObject();
        initUTMTags();
    });

    watch(() => route.path, () => {
        setMetaObject();
    });

    function initUTMTags() {
        const source = new URL(window.location.href).searchParams.get('utm_source');
        const campaign = new URL(window.location.href).searchParams.get('utm_campaign');
        const keyword = new URL(window.location.href).searchParams.get('utm_term');

        const sourceCookie = useCookie('utm-source-cookie', { maxAge: 7948800 });

        if (source)
            sourceCookie.value = source
        else
            if (document.referrer !== "" && !document.referrer.includes(location.origin))
                sourceCookie.value = document.referrer;

        if (campaign) {
            const campaignCookie = useCookie('utm-campaign-cookie', { maxAge: 7948800 });
            campaignCookie.value = campaign;
        }

        if (keyword) {
            const keywordCookie = useCookie('utm-keyword-cookie', { maxAge: 7948800 });
            keywordCookie.value = keyword;
        }
    }

    function setMetaObject() {
        const url = useRequestURL();
        const metaObject = metaTags.get(route.path) ?? metaTags.get('/');

        useHead({
            htmlAttrs: {
                lang: 'en'
            },
            title: metaObject?.title,
            meta: [
                { name: 'description', content: metaObject?.desc }
            ],
            link: [
                { rel: "canonical", href: url.origin + route.path }
            ]
        });
    }
</script>

<style lang="scss">
    @import '@/assets/styles/app.scss';
    @import 'vue-toastification/dist/index.css';
    @import '@/assets/fonts/google-fonts.scss';
</style>