import type { MetaObject } from "@/types/models/meta-object";

const metaTags = new Map<string, MetaObject>([
    ['/', { title: 'Fire Rated Intumescent and Fire Retardant Spray Paint', desc: 'FlameOFF® Coatings manufactures Intumescent Fire Resistant Paint & Fire Retardant Spray tested to  ASTM E-119, ASTM E-84, ICC-ES Listed, NFPA 701.' } as MetaObject],
    ['/401-forbidden', { title: '401 Forbidden', desc: '' } as MetaObject],
    ['/404-not-found', { title: '404 Page Not Found', desc: '' } as MetaObject],
    ['/auth/reset-password', { title: 'Password Recovery', desc: '' } as MetaObject],
    ['/auth/confirm-email', { title: '', desc: '' } as MetaObject],
    ['/intumescent-fire-resistant-paint', { title: 'Fire Barrier Paint', desc: 'Water Based Fire Resistant Intumescent Paint' } as MetaObject],
    ['/fire-retardant-clear-spray', { title: 'Fire Retardant Clear Coating UL Classified', desc: 'Fire Retardant Clear Coating for Wood, Leather, Fabric. Made for commercial, industrial, and residential uses for applicators, architects, building officials, general contractors, and others.' } as MetaObject],
    ['/about/company', { title: 'Intumescent Paint Company in the USA', desc: 'FlameOFF® Coatings, Inc. manufactures fire resistant intumescent paint products for fireproofing applicators, architects, general contactors, painting companies and etc.' } as MetaObject],
    ['/steel-coverage-calculator', { title: 'Steel Coverage Calculator', desc: 'Check FlameOFF Online Steel Coverage Calculator on W-Beams, W-Columns, HSS-Round and etc.' } as MetaObject],
    ['/steel-dimensions-table', { title: 'Steel Dimensions Table', desc: 'Check FlameOFF Online Steel Dimensions Table for W-Beams, W-Columns, HSS-Round and etc.' } as MetaObject],
    ['/testing', { title: 'Testing', desc: 'FlameOFF® Coatings, Inc. provides intumescent fire protection that exceeds many ASTM E-119 listing standards.' } as MetaObject],
    ['/leed', { title: 'LEED', desc: `FlameOFF® Coatings, Inc. contributes to the Materials and Resources Section of the LEED 2009 Edition which is being practiced in today's construction.` } as MetaObject],
    ['/faq', { title: 'Flame retardant and intumescent coatings FAQ', desc: 'FlameOFF® Coatings, Inc. FAQ. A series of questions that are commonly asked by customers: intumescent applications, fire ratings, paint coverage and thickess.' } as MetaObject],
    ['/applying-fire-rated-intumescent-paint-on-steel', { title: 'Applying Fire Rated Intumescent Paint on Steel', desc: 'Intumescent Paint for Steel. Applying FlameOFF® Fire Barrier paint on exposed structural steel can provide additional fire-resistance and increase the hour fire rating.' } as MetaObject],
    ['/applying-fire-rated-intumescent-paint-on-gypsum', { title: 'Applying Fire Rated Intumescent Paint on Gypsum', desc: 'Gypsum Fire Resistance. How to Achieve a Higher Hour Fire Rating on Gypsum Drywall and Save Space with FlameOFF® Intumescent Fire Barrier Paint. Important Considerations.' } as MetaObject],
    ['/applying-fire-rated-intumescent-paint-on-wood', { title: 'Applying Fire Rated Intumescent Paint on Wood', desc: 'Increase a Wood Structure’s Fire Rating applying FlameOFF® Fire Barrier Paint. Achieve 1 hour Fire resistance on Wood Panels, Custom or Irregular Wood Areas.' } as MetaObject],
    ['/applying-fire-retardant-coatings-on-wood', { title: 'Applying Flame-retardant coatings on wood', desc: 'How to achieve fire protection on wood. This clear flame retardant saves wood from flame spread, and reduces smoke production. Achieve fire safety on wood.' } as MetaObject],
    ['/applying-flame-retardant-coatings-on-fabrics', { title: 'Applying Fire Retardant Coatings on Fabrics, Leather and Porous Materials', desc: 'Learn the benefits of applying clear flame retardant coating to various fabrics, leathers, & other porous materials. Stop flame spread and achieve fire safety.' } as MetaObject],
    ['/why-the-size-of-steel-is-important-in-fre-proofing', { title: 'Why the Size of Steel is Important in Fire Proofing', desc: 'Different steel sizes require different approaches to fireproofing. Learn why section factor and dry film thickness matter in fire protection.' } as MetaObject],
    ['/finish-rating-on-drywall', { title: 'Finish Rating on Drywall ', desc: 'Understand finish rating on drywall and how to maximize it with intumescent paint. Additional time can be gained on the finish rating of drywall.' } as MetaObject],
    ['/a-guide-to-full-compliance-in-intumescent-paint', { title: 'A Guide to Full Compliance in Intumescent Paint', desc: 'Quality construction demands adherence to codes for structural integrity; when using tools like intumescent paint for fire safety design, choosing fully compliant products is vital.' } as MetaObject],
    ['/building-with-fire-safety-in-mind', { title: 'Building with Fire Safety in Mind', desc: 'Fire safety design in architecture is a multifaceted approach vital to modern construction. Intumescent paint offers many advantages above alternate solutions.' } as MetaObject],
    ['/engineering-judgment-importance-of-fireproofing', { title: 'Engineering Judgment: Not a Get Out of Jail Free Card', desc: 'Engineering judgements are not a substitute for official flame testing. When making sure a project is fully compliant do not rely on engineering judgements. Read more.' } as MetaObject],
    ['/comprehensive-guide-to-intumescent-paint', { title: 'Comprehensive Guide to Intumescent Paint: Achieving 2-Hour Fire Ratings', desc: 'In this article, we will delve into the power of intumescent paint in achieving 2-hour fire ratings. We will explore its various applications, delve into the science behind its fire-resistant properties, and discuss its benefits for building owners and occupants.' } as MetaObject],
    ['/intumescent-paint-case-studies', { title: 'Case Studies - Applying Intumescent Paint', desc: 'FlameOFF® Coatings clients successfull stories in commercial, residential and industrial applications.' } as MetaObject],
    ['/intumescent-paint-case-studies/world-cup-2022-stadium-achievs-2-hour-fire-rating-on-gypsum', { title: 'World Cup 2022 Stadium Achieves 2 Hour Fire Rating on Gypsum', desc: 'The Ras Abu Aboud Stadium achieves a 2-hour fire rating on ⅝” type X gypsum with the FlameOFF® Coating Fire Barrier Paint. This is the only known intumescent coating that can provide this level of fire protection on gypsum.' } as MetaObject],
    ['/intumescent-paint-case-studies/ambling-construction-group-atlanta-georgia', { title: 'Applying Intumescent Paint for project Ambling Construction Group', desc: 'The retail store wanted a nice white, clean, and paint-like look on the ceiling. Intumescent paint was applied as a fast and cost-effective solution.' } as MetaObject],
    ['/intumescent-paint-case-studies/arc-partners-roswell-georgia', { title: 'Applying Intumescent Paint for project  ARC Partners', desc: 'Intumescent paint as an alternative to multiple layers of gypsum' } as MetaObject],
    ['/intumescent-paint-case-studies/market-street-hotel-augusta-georgia', { title: 'Applying Intumescent Paint for project Market Street Hotel', desc: 'Use of Intumescent paint in historic renovation project' } as MetaObject],
    ['/intumescent-paint-case-studies/memorial-building-deerfield-academy-deerfield-massachusetts', { title: 'Applying Intumescent Paint for project Memorial Building, Deerfield Academy', desc: 'Check the successful project Market Street Hotel. FameOFF fire protective coating application.' } as MetaObject],
    ['/intumescent-paint-case-studies/bell-clark-nashville-tn', { title: 'Applying Intumescent Paint for project Bell/Clark, Nashville, TN', desc: 'Use of Intumescent Coating on a wood decking' } as MetaObject],
    ['/intumescent-paint-case-studies/bartson-construction-atlanta-georgia', { title: 'Applying Intumescent Paint for project Bartson Construction', desc: 'Use of Intumescent paint on a floor/ceiling and roof/ceiling assembly' } as MetaObject],
    ['/intumescent-paint-case-studies/amu-surgeons', { title: 'AMU Surgeons', desc: 'Intumescent paint application on wood ceiling members' } as MetaObject],
    ['/intumescent-paint-case-studies/giland-townhomes', { title: 'Giland Townhomes', desc: 'Use of FlameOFF® Coatings Intumescent paint in Giland Townhomes' } as MetaObject],
    ['/intumescent-paint-case-studies/lenox-properties', { title: 'Lenox Properties', desc: 'Use of FlameOFF® Coatings Intumescent paint in Lenox Properties' } as MetaObject],
    ['/intumescent-paint-case-studies/colon-eye-clinic', { title: 'Colon Eye Clinic', desc: 'Intumescent paint as an alternative to installing drywall' } as MetaObject],
    ['/intumescent-paint-case-studies/turner-barn', { title: 'Turner Barn', desc: 'Use of FlameOFF® Coatings Intumescent paint in Turner Barn' } as MetaObject],
    ['/intumescent-paint-case-studies/urban-housing-authority', { title: 'Urban Housing Authority', desc: 'Use of FlameOFF® Coatings Intumescent paint in Urban Housing Authority' } as MetaObject],
    ['/intumescent-paint-case-studies/2-hour-fire-protection-on-steel-beams-with-Lforma', { title: '2 hour fire protection on steel beams With Lforma', desc: 'While working on a building in New York City, this crew of commercial painters needed an intumescent coating that would generate 2 hour fire protection on steel beams.' } as MetaObject],
    ['/understanding-canadian-standards-of-fire-safety', { title: 'nderstanding Canadian Standards of Fire Safety', desc: 'Canadian fire codes are similar to American standards, but there are differences that should be considered when taking on construction projects.'  } as MetaObject],
    ['/about-us', { title: 'About Us', desc: 'Learn more about FlameOFF® Coatings and our commitment to fire safety.' } as MetaObject],
    ['/products', { title: 'Products', desc: 'Browse our range of fireproof coatings and treatments.' } as MetaObject],
    ['/fireproof-paint', { title: 'Fireproof Paint', desc: 'Explore FlameOFF® fireproof paint options for various applications.' } as MetaObject],
    ['/fire-retardant-clear-spray', { title: 'Fire Retardant Spray', desc: 'Learn about our fire retardant spray products and their benefits.' } as MetaObject],
    ['/applications', { title: 'Applications', desc: 'Discover different applications of FlameOFF® fireproof coatings.' } as MetaObject],
    ['/construction', { title: 'Construction', desc: 'Find out how FlameOFF® products contribute to fire safety in construction.' } as MetaObject],
    ['/commercial', { title: 'Commercial', desc: 'Learn about using FlameOFF® coatings in commercial settings.' } as MetaObject],
    ['/residential', { title: 'Residential', desc: 'Explore residential applications of FlameOFF® fireproof products.' } as MetaObject],
    ['/articles', { title: 'Articles', desc: 'Check out FlameOFF® articles on fire safety and fireproofing.' } as MetaObject],
    ['/understanding-canadian-standards-of-fire-safety', { title: 'Understanding Canadian Standards of Fire Safety', desc: 'Canadian fire codes are similar to American standards, but there are differences that should be considered when taking on construction projects.' } as MetaObject],
    ['/fireproofing-methods-for-wood', { title: 'Fireproofing Methods For Wood', desc: 'Wood is a highly flammable substrate; it may be harder to get it properly fire-rated in construction. This article outlines strategies for fireproofing wood.' } as MetaObject],
    ['/a-review-of-intumescent-paint', { title: 'A Review of Intumescent Paint', desc: 'A review of the role of intumescent coatings in passive fire protection. Learn about the history of intumescent paint and how intumescent coatings work.' } as MetaObject],
    ['/increasing-fire-safety-on-upholstered-furniture', { title: 'Increasing Fire Safety on Upholstered Furniture', desc: 'Upholstered furniture should be properly fire-rated to minimize the negative consequences of a fire emergency. Fire retardant treatment may limit these dangers.' } as MetaObject],
    ['/achieve-2-hour-fire-rating-over-drywall-using-intumescent-paint', { title: 'Achieve a 2 hour Fire Rating over 5/8" Type X Drywall using Intumescent Paint', desc: 'This describes the best way to achieve a 2-hour fire rating with only one layer of Type X drywall. Learn about gypsum, types of drywall, and intumescent paint.' } as MetaObject],
    ['/flameoff-fire-barrier-paint-for-haunted-houses', { title: 'FlameOFF® Fire Barrier Paint for Haunted Houses', desc: 'Intumescent paint can be a convenient solution when planning a strategy to ensure that a haunted house, which includes different types of materials, will meet all code requirements.' } as MetaObject],
    ['/how-to-achieve-a-higher-fire-rating-on-gypsum-metal-and-wood-with-intumescent-paint', { title: 'How to Achieve a Higher Fire Rating on Gypsum, Metal and Wood', desc: 'Intumescent coating products such as intumescent paint are an option to be considered for enhancing a structure’s fire rating, as the products are recognized by IBC as valid for protection against heat and fire and can often provide a cheaper and more aesthetic solution.' } as MetaObject],
    ['/when-fireproofing-building-spray-is-king', { title: 'Fireproof a Building - Why to choose Intumescent Paint', desc: 'There are two main ways to fireproof a building – by using cementitious building materials or by covering your existing components with intumescent paint. Which one to choose?' } as MetaObject],
    ['/company-news', { title: 'Company News' } as MetaObject],
    ['/contact-us', { title: 'Contact Us', desc: 'FlameOFF® Coatings contact information page. Get in touch with our representative by phone or via email.' } as MetaObject],
    ['/terms-and-conditions', { title: 'Terms and Conditions', desc: 'Review our terms and conditions of Sale.' } as MetaObject],
    ['/privacy-policy', { title: 'Privacy notice', desc: 'FlameOFF® is dedicated to protecting the privacy of our users by taking all possible measures to protect their personally identifiable information.' } as MetaObject],
    ['/return-policy', { title: 'Return policy' } as MetaObject],
    ['/terms-of-service', { title: 'Terms Of Service' } as MetaObject],
    ['/term-of-use', { title: 'Terms of Use', desc: 'Review our terms of use which must be observed and followed.' } as MetaObject],
    ['/shipping-policy', { title: 'Shipping Information', desc: 'Shipping policy. Important information around shipping when an order is placed online.' } as MetaObject],
    ['/login', { title: 'Login', desc: 'Login Page' } as MetaObject],
    ['/register', { title: 'Register', desc: 'FlameOFF Coatings manufactures Intumescent Fire Resistant Paint & Fire Retardant Spray tested to ASTM E119, ASTM E84, ICC-ES Listed, NFPA 701.' } as MetaObject],
    ['/passwordrecovery', { title: 'Password Recovery', desc: 'Password recovery' } as MetaObject],
    ['/checkout', { title: 'Shopping Cart: Buy Intumescent Paint or Fire Retardant Treatment', desc: 'Fast and easy checkout with your FlameOFF items. Obtain effective fireproof coatings and treatments at a reasonable price.' } as MetaObject],
    ['/cart', { title: 'Shopping Cart: Buy Intumescent Paint or Fire Retardant Treatment', desc: 'Fast and easy checkout with your FlameOFF items. Obtain effective fireproof coatings and treatments at a reasonable price.' } as MetaObject]
]);

export default metaTags;